interface FeedbackRating {
  communication: string
  conseil: string
  reactivite: string
  deliverables: string
  deadlines: string
  global: string
}

const TOPIC = {
  communication: 'COM',
  conseil: 'UND',
  reactivite: 'REA',
  delivrable: 'DEL',
  deadlines: 'DEA',
  global: 'GLO',
} as const

export const extractFeedbackRatings = (feedback: string): FeedbackRating => {
  const extractComments = (text: string, prefix: string, nextPrefix?: string): string => {
    if (!text.includes(`${prefix}:`)) {
      if (prefix === TOPIC.global) {
        return text
      }
      return ''
    }
    const line = text.split(`${prefix}:`)[1]
    var result = '';
    if (!nextPrefix) {
      result = line
    } else {
      result = line.split(`${nextPrefix}:`)[0]
    }
    return result
  }

  return {
    communication: extractComments(feedback, TOPIC.communication, TOPIC.conseil),
    conseil: extractComments(feedback, TOPIC.conseil, TOPIC.reactivite),
    reactivite: extractComments(feedback, TOPIC.reactivite, TOPIC.delivrable),
    deliverables: extractComments(feedback, TOPIC.delivrable, TOPIC.deadlines),
    deadlines: extractComments(feedback, TOPIC.deadlines, TOPIC.global),
    global: extractComments(feedback, TOPIC.global, ''),
  }
}
