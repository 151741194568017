import {
  NotificationListAtom,
  isDisplayAllNotification,
} from '@/atom/notification'
import SortIcon from '@/components/icons/SortIcon'
import {
  INotificationResponse,
  INotificationType,
} from '@/types/INotificationResponse'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import SplittedNotifications from './splitted-notification'
import { ScrollArea } from '@radix-ui/react-scroll-area'
import EmptyData from '@/components/empty-data'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import { DropdownMenuTrigger } from '@radix-ui/react-dropdown-menu'
import NotSeen from '@/components/icons/NotSeen'
import ArchivedIcon from '@/components/icons/ArchivedIcon'
import BellIcon from '@/components/icons/BellIcon'
import { Check } from 'lucide-react'
import CheckIcon from '@/components/icons/CheckIcon'
import { getNotificationList } from '@/services/api-client'

const dropdownMenuList = [
  {
    label: 'Lus et non lus',
    value: 'notseen-seen',
    icon: <BellIcon />,
  },
  {
    label: 'Non lus',
    value: 'notseen',
    icon: <NotSeen />,
  },
  {
    label: 'Archivés',
    value: 'archived',
    icon: <ArchivedIcon />,
  },
]

type FilterType = 'archived' | 'notseen' | 'notseen-seen'

export default function SideBarNotification({ filterDropdownRef }: any) {
  const [notificationsListAtomState] = useAtom(NotificationListAtom)
  const [isDisplayNotification, setIsDisplayNotification] = useAtom(
    isDisplayAllNotification
  )
  const [filterResult, setFilterResult] = useState<INotificationType[]>([])
  const [activeFilter, setActiveFilter] = useState<FilterType>('notseen-seen')
  const [splittedNotification, setSplittedNotification] = useState<{
    today: INotificationType[]
    yesterday: INotificationType[]
    older: INotificationType[]
  }>({
    today: [],
    yesterday: [],
    older: [],
  })

  function splitNotificationByDate(notifications: INotificationType[]) {
    // Get today's and yesterday's date strings
    const today = new Date()
    const todayDateStr = today.toISOString().split('T')[0]

    const yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    const yesterdayDateStr = yesterday.toISOString().split('T')[0]

    // Arrays to hold the split results
    const todayArray: INotificationType[] = []
    const yesterdayArray: INotificationType[] = []
    const restArray: INotificationType[] = []

    // Split the data based on the last_activity date
    notifications.forEach((item) => {
      const lastActivityDateStr = item.last_activity.split('T')[0]

      if (lastActivityDateStr === todayDateStr) {
        todayArray.push(item)
      } else if (lastActivityDateStr === yesterdayDateStr) {
        yesterdayArray.push(item)
      } else {
        restArray.push(item)
      }
    })
    setSplittedNotification(() => ({
      today: todayArray,
      yesterday: yesterdayArray,
      older: restArray,
    }))
  }

  function handleFilter(filter: FilterType) {
    switch (filter) {
      case 'notseen-seen':
        setFilterResult(notificationsListAtomState)
        break
      case 'notseen':
        setFilterResult(
          notificationsListAtomState.filter(
            (item) => item.last_seen > item.last_activity
          )
        )
      // ARCHIVE IS NOT IMPLEMENTED YET
    }
  }

  async function getNotification() {
    try {
      const { data }: { data: INotificationResponse } =
        await getNotificationList()
      setFilterResult(data.notifications)
    } catch (error) {
      throw new Error('Unable to fetch notifications. An error occured')
    }
  }

  useEffect(() => {
    getNotification()
    return () => {
      setIsDisplayNotification({
        triggerRef: isDisplayNotification?.triggerRef,
        isShow: false,
      })
    }
  }, [])
  useEffect(() => {
    handleFilter(activeFilter as FilterType)
  }, [activeFilter])

  useEffect(() => {
    if (filterResult.length) {
      splitNotificationByDate(filterResult)
    }
  }, [filterResult])

  return (
    <div>
      <h3 className="flex items-center justify-between px-8 py-6 text-xl text-primary-dark font-semibold border-b [&_path]:stroke-neutral-darker">
        Notifications
        <DropdownMenu modal={false} >
          <DropdownMenuTrigger>
            <SortIcon
              width={20}
              height={20}
              className="hover:cursor-pointer"
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="end"
            className="border-neutral-dark w-80 shadow-sm p-4 [&>div]:py-1 [&>div]:px-2 flex flex-col gap-2"
            ref={filterDropdownRef}
          >
            {dropdownMenuList.map((item) => (
              <DropdownMenuItem
                key={item.label}
                onClick={() => setActiveFilter(item.value as FilterType)}
              >
                <span className="flex hover:cursor-pointer justify-between w-full">
                  <span className="flex gap-2 text-base font-normal items-center">
                    {item.icon} {item.label}
                  </span>{' '}
                  {activeFilter === item.value ? <Check /> : ''}
                </span>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </h3>
      {filterResult.length ? (
        <ScrollArea className="h-[calc(100vh-8rem)] overflow-y-auto">
          {/* TODAY */}
          {splittedNotification.today.length ? (
            <SplittedNotifications
              label="Aujourd'hui"
              notificationList={splittedNotification.today}
            />
          ) : (
            ''
          )}

          {/* YESTERDAY */}
          {splittedNotification.yesterday.length ? (
            <SplittedNotifications
              label="Hier"
              notificationList={splittedNotification.yesterday}
            />
          ) : (
            ''
          )}

          {/* OLDER */}
          {splittedNotification.older.length ? (
            <SplittedNotifications
              label="Plus ancien"
              notificationList={splittedNotification.older}
            />
          ) : (
            ''
          )}
        </ScrollArea>
      ) : (
        <div className="pt-80">
          <EmptyData
            icon={<CheckIcon />}
            msg="Aucune notification récente"
            bgIcon="bg-success/10"
          />
        </div>
      )}
    </div>
  )
}
