import useClientSatisfaction, {
  OPINION_LIST,
  TIMELINE_DATA,
} from '@/atom/client-satisfaction'
import { Button } from '@/components/ui/button'
import { toastSuccessMsg, TOAST_ERROR_MSG } from '@/configs/constants'
import { ROUTES } from '@/configs/route-naming'
import { useToast } from '@/hooks/use-toast'
import { postClientSatisfaction } from '@/services/client-satification.service'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function GradeRendering({
  gradeItem,
}: Readonly<{
  gradeItem: { comment: string; title: string; key: string; grade: number }
}>) {
  const icon = OPINION_LIST.find((item) => item.id === gradeItem.grade)?.icon

  return (
    <div className="border-b flex py-6 px-8 flex-col gap-y-4">
      <p className="font-semibold flex items-center gap-x-2">
        {gradeItem.title} {icon}
      </p>
      {gradeItem.comment ? (
        <p className="text-sm whitespace-pre-line">{gradeItem.comment}</p>
      ) : (
        <p className="text-sm text-neutral-darker/60">
          Aucune remarque renseigné
        </p>
      )}
    </div>
  )
}

interface PayloadType {
  grade_deliverables: number
  grade_reactivity: number
  grade_communication: number
  grade_understanding: number
  grade_deadlines: number
  sprint: string
  comment: string
}

export default function SummarySatisfactionStep() {
  const navigate = useNavigate()
  const { stepData, handlePrevSection } = useClientSatisfaction()
  const [globalState, setGlobalState] = useState('')
  const [payload, setPayload] = useState<PayloadType | {}>({})
  const [isLoadRequest, setIsLoadRequest] = useState(false)
  const { toast } = useToast()

  useEffect(() => {
    const globalSection = stepData.find((item) => item.key === 'GLO')
    globalSection && setGlobalState(globalSection?.comment)
    craftPayload()
  }, [stepData])

  const craftPayload = () => {
    stepData.forEach((item) => {
      switch (item.key) {
        case 'COM':
          setPayload((prev) => ({ ...prev, grade_communication: item.grade }))
          break
        case 'UND':
          setPayload((prev) => ({ ...prev, grade_understanding: item.grade }))
          break
        case 'REA':
          setPayload((prev) => ({ ...prev, grade_reactivity: item.grade }))
          break
        case 'DEL':
          setPayload((prev) => ({ ...prev, grade_deliverables: item.grade }))
          break
        case 'DEA':
          setPayload((prev) => ({ ...prev, grade_deadlines: item.grade }))
          break
      }
    })
    const comment = stepData.reduce(
      (acc, curr) => acc + `${curr.key}: ${curr.comment}\n`,
      ''
    )
    setPayload((prev) => ({ ...prev, comment }))
  }

  const handleCreateFeedback = async () => {
    setIsLoadRequest(true)
    try {
      await postClientSatisfaction(payload as PayloadType)
      toast(toastSuccessMsg("Avis client ajouté avec succés"))
      navigate(ROUTES.PROTECTED.FEEDBACK)
    } catch (error) {
      toast(TOAST_ERROR_MSG)
      setIsLoadRequest(false)
    }
  }
  return (
    <div className="text-primary-dark">
      <div className="border-b py-6 px-8">
        <h3 className="font-semibold text-xl">Récaptilatif de l’avis client</h3>
      </div>
      <div className="border-b px-8 py-6">
        <div className="bg-neutral-light rounded-md px-8 py-6">
          <p className="font-semibold">Avis global</p>
          <p className="text-sm whitespace-pre-line">{globalState}</p>
        </div>
      </div>
      {TIMELINE_DATA.map((item, index) => (
        <GradeRendering
          key={item.id}
          gradeItem={{
            comment: stepData[index].comment,
            key: stepData[index].key,
            title: item.title,
            grade: stepData[index].grade,
          }}
        />
      ))}
      <div className="px-8 py-6 flex items-center justify-between">
        <Button
          type="button"
          onClick={() => handlePrevSection('', 2)}
          className="bg-neutral-dark hover:bg-neutral-dark border shadow-none text-primary-dark"
        >
          Retour
        </Button>
        <Button
          isLoading={isLoadRequest}
          onClick={() => handleCreateFeedback()}
        >
          Ajouter l’avis client
        </Button>
      </div>
    </div>
  )
}
